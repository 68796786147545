module.exports = {
  COLORS: {
    stepForwardNormal: '#52c41a',
    stepForwardEnhanced: '#398912',
    stepBackwardNormal: '#ff7b02',
    stepBackwardEnhanced: '#b35601',
    primary: '#a12596',
    primaryDark: '#811e78',
    fadedPrimary: '#ab57a4',
    lightPrimary: '#cc9bc7',
    primaryOrange: '#eb7100',
    secondary: '#52c41a',
    secondaryHover: '#409913',
    secondaryLight: '#FFCB52',
    link: '#7D00A3',
    highlight: '#FFA32A',
    faded: '#767676',
    black: '#000000',
    white: '#ffffff',
    // TODO: We should rethink this name
    backgroundAlternative: '#F27979',
    backgroundLightGray: '#0000000d',
    backgroundLightOrange: '#FDF1E6',
    gettingHelpBackground: 'rgba(255, 123, 2, 0.05)',
    volunteeringBackground: 'rgba(129, 30, 120, 0.05)',
    success: '#52C41A',
    brandOrange: '#eb7100',
    lightBlue: '#1890ff',
    strokeCards: '#F0F0F0',
    reasonsIconBg: '#f9f9ff',
    greyBackground: '#E5E5E5',
    footerLine: '#c4c4c4',
    rgb: {
      success: '82,196,26',
      primary: '24, 144, 255',
      warning: '242, 121, 121',
      brandOrange: '235,113,0,1',
    },
  },
};
